import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  batch,
  createContext,
  JSX,
  onMount,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { getRequestEvent } from "solid-js/web";
import {
  getProcurementWalletBalance,
  getProductSearch,
  getUserProfile,
  simulateCart,
} from "~/server/apis/client_apis";
import {
  getProcurementRouteData,
  ProcurementRouteData,
} from "~/server/data/procurement_route_data";
import { ProcurementProductWithName } from "~/server/types/order";
import { SearchResults } from "~/server/types/search";

type ProcurementProviderProps = {
  children: JSX.Element;
};

type ProductDetail = {
  logoUrl: string;
  brandName: string;
};

export type CartProduct = {
  detail: ProductDetail;
  productId: string;
  amount: number;
  denomination: number;
  quantity: number;
};

export type Cart = {
  totalAmount: number;
  totalQuantity: number;
  totalDiscount: number;
  products: CartProduct[];
  proformaInvoiceId?: string;
};

type ProcurementContextValue = {
  procurement: {
    cart: Cart;
    allBrands: SearchResults;
    userProfile: { name: string; logo: string; email: string; phone: string };
    balance: number;
  };
  addToCart: (newProduct: CartProduct) => void;
  updateQuantity: (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => void;
  clearCart: () => void;
  updatePIInfo: (id: string) => void;
  clearAndAddProductsToCart: (products: ProcurementProductWithName[]) => void;
};

const ProcurementContext = createContext<ProcurementContextValue>();

const getProcurementRouteData$C = cache(getProcurementRouteData, "procurement");

export function ProcurementProvider(props: ProcurementProviderProps) {
  const routeData: Accessor<ProcurementRouteData | undefined> =
    createAsync<ProcurementRouteData>(() => getProcurementRouteData$C());

  const [procurement, setProcurement] = createStore({
    cart: {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [] as CartProduct[],
      proformaInvoiceId: undefined as undefined | string,
    },
    allBrands: routeData()?.allBrands as SearchResults,
    userProfile: {
      name: routeData()?.userProfile?.name ?? "Team",
      logo: routeData()?.userProfile?.logo ?? "",
      email: routeData()?.userProfile.email ?? "",
      phone: routeData()?.userProfile.phone ?? "",
    },
    balance: routeData()?.balance ?? 0,
  });

  onMount(async () => {
    if (!routeData()) {
      const allBrands = await getProductSearch("", {
        pageNo: 0,
        limit: 100,
      });

      const { balance } = await getProcurementWalletBalance();
      const userProfile = await getUserProfile();

      const requestEvent = getRequestEvent();

      setProcurement("userProfile", "logo", requestEvent?.locals.clientLogoUrl);
      if (userProfile.firstName && userProfile.lastName) {
        setProcurement(
          "userProfile",
          "name",
          `${userProfile.firstName} ${userProfile.lastName}`
        );
      }
      setProcurement("userProfile", "phone", userProfile.phoneNumber ?? "");
      setProcurement("userProfile", "email", userProfile.email ?? "");

      setProcurement("allBrands", allBrands);
      setProcurement("balance", balance);
    }
  });

  const simulateAndUpdateCartDiscount = async () => {
    const simulatePayload = {
      totalAmount: procurement.cart.totalAmount,
      products: procurement.cart.products.map((product) => ({
        productId: product.productId,
        amount: product.amount,
        denominationDetails: [
          {
            denomination: product.denomination,
            quantity: product.quantity,
          },
        ],
      })),
    };

    try {
      const response = await simulateCart(simulatePayload);

      if (response?.data[0].totalDiscount) {
        setProcurement("cart", "totalDiscount", response.data[0].totalDiscount);
        setProcurement("cart", "totalAmount", response.data[0].totalAmount);
      }
    } catch (error) {
      console.error("Error simulating cart:", error);
    }
  };

  const addToCart = async (newProduct: CartProduct) => {
    const existingProductIndex = procurement.cart.products.findIndex(
      (product) =>
        product.productId === newProduct.productId &&
        product.denomination === newProduct.denomination
    );

    if (existingProductIndex !== -1) {
      setProcurement(
        "cart",
        "products",
        existingProductIndex,
        "quantity",
        (quantity) => quantity + newProduct.quantity
      );
      setProcurement(
        "cart",
        "products",
        existingProductIndex,
        "denomination",
        () => newProduct.denomination
      );
      setProcurement(
        "cart",
        "products",
        existingProductIndex,
        "amount",
        (amount) => amount + newProduct.amount
      );
    } else {
      setProcurement("cart", "products", (products) => [
        ...products,
        newProduct,
      ]);
    }

    setProcurement("cart", "totalQuantity", procurement.cart.products.length);
    setProcurement(
      "cart",
      "totalAmount",
      (amount) => amount + newProduct.amount
    );
    simulateAndUpdateCartDiscount();
  };

  const clearAndAddProductsToCart = async (
    products: ProcurementProductWithName[]
  ) => {
    clearCart();
    products.map((product) => {
      product.denominationDetails.length == 1
        ? setProcurement("cart", "products", (products) => [
            ...products,
            {
              productId: product.productId,
              amount: product.amount,
              denomination: product.denominationDetails[0].denomination,
              quantity: product.denominationDetails[0].quantity,
              detail: {
                logoUrl: "",
                brandName: product.productName,
              },
            },
          ])
        : product.denominationDetails.map((pdt) => {
            setProcurement("cart", "products", (products) => [
              ...products,
              {
                productId: product.productId,
                amount: product.amount,
                denomination: pdt.denomination,
                quantity: pdt.quantity,
                detail: {
                  logoUrl: "",
                  brandName: product.productName,
                },
              },
            ]);
          });
      setProcurement(
        "cart",
        "totalAmount",
        (amount) => amount + product.amount
      );
    });

    setProcurement("cart", "totalQuantity", procurement.cart.products.length);

    simulateAndUpdateCartDiscount();
  };

  const updateQuantity = async (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => {
    const existingProductIndex = procurement.cart.products.findIndex(
      (product) =>
        product.productId === productId && product.denomination === denomination
    );

    if (existingProductIndex !== -1) {
      const existingProduct = procurement.cart.products[existingProductIndex];
      const quantityDifference = newQuantity - existingProduct.quantity;
      const amountDifference = quantityDifference * denomination;

      batch(() => {
        if (newQuantity === 0) {
          setProcurement("cart", "products", (products) =>
            products.filter((_, index) => index !== existingProductIndex)
          );
        } else {
          setProcurement(
            "cart",
            "products",
            existingProductIndex,
            "quantity",
            newQuantity
          );
          setProcurement(
            "cart",
            "products",
            existingProductIndex,
            "amount",
            existingProduct.denomination * newQuantity
          );
        }

        const updatedTotalAmount = procurement.cart.products.reduce(
          (total, product) =>
            total +
            (product.productId === productId &&
            product.denomination === denomination
              ? denomination * newQuantity
              : product.amount),
          0
        );

        setProcurement(
          "cart",
          "totalQuantity",
          procurement.cart.products.length
        );
        setProcurement("cart", "totalAmount", updatedTotalAmount);
      });

      if (procurement.cart.products.length === 0) {
        setProcurement("cart", "totalQuantity", 0);
        return;
      }

      simulateAndUpdateCartDiscount();
    }
  };
  const updatePIInfo = (id: string) => {
    setProcurement("cart", {
      proformaInvoiceId: id,
    });
  };

  const clearCart = () => {
    setProcurement("cart", {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [],
      proformaInvoiceId: undefined,
    });
  };

  return (
    <ProcurementContext.Provider
      value={{
        procurement,
        addToCart,
        updateQuantity,
        clearCart,
        updatePIInfo,
        clearAndAddProductsToCart,
      }}
    >
      {props.children}
    </ProcurementContext.Provider>
  );
}

export function useProcurement() {
  return useContext(ProcurementContext)!;
}
